<template>
  <v-card>
    <v-card-title class="text-h5" primary-title>
      <v-icon :left="true">
        {{ $vuetify.icons.values.alert }}
      </v-icon>
      <h6 class="text-h6">
        {{ $trans("remove_image") }}
      </h6>
    </v-card-title>

    <v-card-text>
      <p class="text-subtitle-1">
        {{ $trans("remove_image_warning") }}
      </p>
      <p class="accent_light--text text-subtitle-1">
        {{ $trans("no_way_to_undo_action") }}
      </p>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="light" text @click="$emit('close')">
        {{ $trans("cancel") }}
      </v-btn>
      <v-btn
        :disabled="isSending || isLoading"
        color="accent_light"
        text
        @click="removeAvatar(getConfirmDialog.data)"
      >
        {{ $trans("remove") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import userActions from "@/calendesk/mixins/userActions";

export default {
  name: "RemoveAccountDialog",
  mixins: [userActions],
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapMutations({
      setSelectedUser: "user/SET_SELECTED_USER",
    }),
    ...mapActions({
      updateUser: "user/update",
    }),
    removeAvatar(id) {
      this.$emit("loading", true);
      this.isLoading = true;
      const myObj = {
        id,
        default_image_id: null,
      };
      this.updateUser(myObj)
        .then((response) => {
          this.setSelectedUser(response);
        })
        .catch((error) => {
          errorNotification("error_occurred", error);
        })
        .finally(() => {
          this.isLoading = true;
          this.$emit("close");
          this.$emit("loading", false);
          this.setIsSending(false);
        });
    },
  },
};
</script>
